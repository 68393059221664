<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Stat from "@/components/widgets/stat";
// import SalesAnalytics from "../dashboard/sales-analytics.vue";
// import { fabric } from "fabric";
import Multiselect from "vue-multiselect";
import btnComponent from "@/components/btnComponent";
import { required } from "vuelidate/lib/validators";

export default {
  page: {
    title: "ข้อมูลการขาย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    Multiselect,
    btnComponent,
    // Stat,
    // SalesAnalytics,
  },
  data() {
    return {
      title: "ข้อมูลการขาย",

      items: [
        {
          text: "วิเคราะห์ข้อมูล",
        },
        {
          text: "ข้อมูลการขาย",
          active: true,
        },
      ],
      sum: 0,
      load: false,
      errormessage: "กรุณากรอกข้อมูล",
      isHidden: false,
      submitform: false,
      isLoading: false,
      loading: undefined,
      sortBy: "age",
      sortDesc: false,
      bordered: true,
      sumBefore: 0,
      summary: "",
      yearOption: [
        // { nameTh: "2022" },
        // { nameTh: "2021" },
        // { nameTh: "2020" },
        // { nameTh: "2019" },
      ],
      fields: [
        {
          key: "branch",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "target",
          label: "เป้า",
          sortable: true,
        },
        {
          key: "sales",
          label: "ขาย",
          sortable: true,
        },
        {
          key: "diff",
          label: "เทียบเป้า",
          sortable: true,
        },

        {
          key: "percent",
          label: "%(เทียบเป้า)",
          sortable: true,
          variant: "danger",
        },
      ],
      optionMonth: [
        { id: "01", name: "มกราคม" },
        { id: "02", name: "กุมภาพันธ์" },
        { id: "03", name: "มีนาคม " },
        { id: "04", name: "เมษายน" },
        { id: "05", name: "พฤษภาคม" },
        { id: "06", name: "มิถุนายน" },
        { id: "07", name: "กรกฎาคม" },
        { id: "08", name: "สิงหาคม" },
        { id: "09", name: "กันยายน" },
        { id: "10", name: "ตุลาคม" },
        { id: "11", name: "พฤศจิกายน" },
        { id: "12", name: "ธันวาคม" },
      ],
      fieldsDiff: [
        {
          key: "model",
          label: "ชื่อ",
          sortable: true,
        },
        {
          key: "target",
          label: "เป้า",
          sortable: true,
        },
        {
          key: "sales",
          label: "ขาย",
          sortable: true,
        },
        {
          key: "diff",
          label: "เทียบเป้า",
          sortable: true,
        },

        {
          key: "percent",
          label: "%(เทียบเป้า)",
          sortable: true,
          variant: "danger",
        },
      ],
      sumAuto: 0,
      sumFamily: 0,
      sumSport: 0,
      sumAutoBe: 0,
      sumFamilyBe: 0,
      sumSportBe: 0,
      start_loading: false,
      overlayFlag: false,
      filter: {
        branchIdSearch: [],
        fromMonth: "",
        toMonth: "",
        year: "",
      },
      filterOn: [],
      car_data: [],
      new_car_data: [],
      data_array: [],
      unique: [],
      sale_total_new: [],
      arr: [],
      arr2: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      user: "",
      branchId: "",
      localDataBranch: "",
      dataDashboard: "",
      DateSearch: "",
      localData: [],
      rowsData: [],
      branchSalesCompareWithSalesTarget: [],
      yearSummary: [],
      data3Years: [],
      diffSummary: [],
      diffSummaryAutoModels: [],
      diffSummaryFamilyModels: [],
      diffSummarySportModels: [],
      diffSummarySummary: [],
      branchIdArr: [],

      chartOptions: {
        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: "",
          palette: "palette5",
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            title: {
              text: "คัน",
              style: {
                color: "#000",
              },
            },
          },
        ],
      },
      chartOptions2: {
        chart: {
          height: 300,
          type: "bar",
          zoom: {
            enabled: false,
          },
        },

        dataLabels: {
          enabled: false,
        },

        theme: {
          mode: "",
          palette: "palette5",
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            title: {
              text: "คัน",
              style: {
                color: "#000",
              },
            },
          },
        ],
        title: {
          text: "Auto",
          align: "center",
          floating: true,
        },
      },
      chartOptions3: {
        chart: {
          height: 300,
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: "",
          palette: "palette5",
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            title: {
              text: "คัน",
              style: {
                color: "#000",
              },
            },
          },
        ],
        title: {
          text: "Sport",
          align: "center",
          floating: true,
        },
      },
      chartOptions4: {
        chart: {
          height: 300,
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: "",
          palette: "palette5",
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            title: {
              text: "คัน",
              style: {
                color: "#000",
              },
            },
          },
        ],
        title: {
          text: "Family",
          align: "center",
          floating: true,
        },
      },
      index: "",
      name1: "",
      series: [
        {
          name: "",
          type: "line",
          data: [],
        },
        {
          name: "",
          type: "line",
          data: [],
        },
        {
          name: "",
          type: "line",
          data: [],
        },
      ],
      series2: [
        {
          name: "",
          type: "bar",
          data: [],
        },
        {
          name: "",
          type: "bar",
          data: [],
        },
        {
          name: "",
          type: "bar",
          data: [],
        },
      ],
      series3: [
        {
          name: "",
          type: "bar",
          data: [],
        },
        {
          name: "",
          type: "bar",
          data: [],
        },
        {
          name: "",
          type: "bar",
          data: [],
        },
      ],
      series4: [
        {
          name: "",
          type: "bar",
          data: [],
        },
        {
          name: "",
          type: "bar",
          data: [],
        },
        {
          name: "",
          type: "bar",
          data: [],
        },
      ],
    };
  },
  validations: {
    filter: {
      year: {
        required,
      },
      fromMonth: {
        required,
      },
      toMonth: {
        required,
      },
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });

    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
  },
  created() {
    this.getLocalData();
    this.inittahun();
  },

  methods: {
    inittahun() {
      let currentYear = new Date().getFullYear();
      let earliestYear = 2001;
      let years = [];
      while (currentYear >= earliestYear) {
        years.push(currentYear);
        currentYear -= 1;
      }
      this.yearOption = years;
    },
    tooltipForm(branchIdSearch) {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.filter.$invalid != true) {
        this.handleSearch(branchIdSearch);
      }
    },
    handleSearch(branchId) {
      const arr = [];
      branchId.forEach((item) => {
        arr.push(item.branchId);
      });
      this.branchIdArr = arr;

      this.getData();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      // console.log('localdata =>', this.localData);
      this.user = user;
      this.localDataBranch = user.userAccessBranch;
      this.branchId = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
    },
    splitChunks(sourceArray, chunkSize) {
      if (chunkSize <= 0) throw "chunkSize must be greater than 0";
      let result = [];
      for (var i = 0; i < sourceArray.length; i += chunkSize) {
        result[i / chunkSize] = sourceArray.slice(i, i + chunkSize);
      }
      return result;
    },
    checkData() {
      var sumAuto = 0;
      var sumFamily = 0;
      var sumSport = 0;
      var sumAutoBe = 0;
      var sumFamilyBe = 0;
      var sumSportBe = 0;
      this.diffSummaryAutoModels.forEach((s1, index) => {
        if (index == this.diffSummaryAutoModels.length - 1) {
          sumAuto = s1.detail[s1.detail.length - 1].diff;
          // return sumAuto;
        } else if (index == this.diffSummaryAutoModels.length - 2) {
          sumAutoBe = s1.detail[s1.detail.length - 2].diff;
        }
      });
      this.diffSummaryFamilyModels.forEach((s2, index) => {
        if (index == this.diffSummaryFamilyModels.length - 1) {
          sumFamily = s2.detail[s2.detail.length - 1].diff;
          // return sumFamily;
        } else if (index == this.diffSummaryFamilyModels.length - 2) {
          sumFamilyBe = s2.detail[s2.detail.length - 2].diff;
        }
      });
      this.diffSummarySportModels.forEach((s3, index) => {
        if (index == this.diffSummarySportModels.length - 1) {
          sumSport = s3.detail[s3.detail.length - 1].diff;
          // return sumSport;
        } else if (index == this.diffSummarySportModels.length - 2) {
          sumSportBe = s3.detail[s3.detail.length - 2].diff;
        }
      });
      this.sumAuto = sumAuto;
      this.sumFamily = sumFamily;
      this.sumSport = sumSport;

      this.sumAutoBe = sumAutoBe;
      this.sumFamilyBe = sumFamilyBe;
      this.sumSportBe = sumSportBe;

      // const sum = sumAuto + sumFamily + sumSport;
      // const sumBefore = sumAutoBe + sumFamilyBe + sumSportBe;
      // console.log(sum);
      this.sum = this.diffSummarySummary[this.filter.year];
      this.sumBefore = this.diffSummarySummary[this.filter.year - 1];
      let summa = "";
      if (this.sum != 0 && this.sumBefore != 0) {
        summa = `${(this.sum / this.sumBefore) * 100}%`;
      } else if (this.sum == 0) {
        summa = "ไม่พบยอดขายปีที่ระบุ";
      } else if (this.sumBefore == 0) {
        summa = "ไม่พบยอดขายปีก่อนหน้า";
      }
      this.summary = summa;
    },

    limitText(count) {
      return `and ${count} other countries`;
    },
    getData: function () {
      this.loading = true; //skeleton true
      this.load = false;
      this.overlayFlag = true;
      useNetw
        .get("api/sale-analytic", {
          params: {
            year: this.filter.year,
            fromMonth: this.filter.fromMonth.id,
            toMonth: this.filter.toMonth.id,
            branchId: this.branchIdArr,
          },
        })
        .then((response) => {
          this.rowsData = response.data;
          this.branchSalesCompareWithSalesTarget =
            response.data.branchSalesCompareWithSalesTarget;
          this.yearSummary = response.data.yearSummary;
          // this.diffSummary = response.data.diffSummary;
          this.diffSummaryAutoModels = response.data.diffSummary.autoModels;
          this.diffSummaryFamilyModels = response.data.diffSummary.familyModels;
          this.diffSummarySportModels = response.data.diffSummary.sportModels;
          this.diffSummarySummary = response.data.diffSummary.summary;
          this.data3Years = response.data.yearSummary.data3Years;
          if (response.data.yearSummary.data3Years.length > 0) {
            this.isHidden = true;
            const categoriesMonth = [];
            const dataQuantity = [];
            const dataQuantity2 = [];
            const dataQuantity3 = [];

            const categoriesmodelName = [];
            const categoriesSportName = [];
            const categoriesFamilyName = [];
            const dataQuantitymodel = [];
            const dataQuantitymodel2 = [];
            const dataQuantitymodel3 = [];

            const dataQuantitySport = [];
            const dataQuantitySport2 = [];
            const dataQuantitySport3 = [];

            const dataQuantityFamily = [];
            const dataQuantityFamily2 = [];
            const dataQuantityFamily3 = [];

            this.series[0].name = this.data3Years[0].year;
            this.series[1].name = this.data3Years[1].year;
            this.series[2].name = this.data3Years[2].year;

            this.series2[0].name = this.data3Years[0].year;
            this.series2[1].name = this.data3Years[1].year;
            this.series2[2].name = this.data3Years[2].year;

            this.series3[0].name = this.data3Years[0].year;
            this.series3[1].name = this.data3Years[1].year;
            this.series3[2].name = this.data3Years[2].year;

            this.series4[0].name = this.data3Years[0].year;
            this.series4[1].name = this.data3Years[1].year;
            this.series4[2].name = this.data3Years[2].year;
            this.data3Years.forEach((e, index) => {
              if (index == 0) {
                e.dataMonth.forEach((item) => {
                  categoriesMonth.push(item.month);
                  dataQuantity.push(item.quantity);
                });
                e.dataAutoModel.forEach((item) => {
                  categoriesmodelName.push(item.modelName);
                  dataQuantitymodel.push(item.quantity);
                });

                e.dataSportModel.forEach((item) => {
                  categoriesSportName.push(item.modelName);
                  dataQuantitySport.push(item.quantity);
                });

                e.dataFamilyModel.forEach((item) => {
                  categoriesFamilyName.push(item.modelName);
                  dataQuantityFamily.push(item.quantity);
                });
              } else if (index == 1) {
                e.dataMonth.forEach((item) => {
                  categoriesMonth.push(item.month);
                  dataQuantity2.push(item.quantity);
                });
                e.dataAutoModel.forEach((item) => {
                  categoriesmodelName.push(item.modelName);
                  dataQuantitymodel2.push(item.quantity);
                });

                e.dataSportModel.forEach((item) => {
                  categoriesSportName.push(item.modelName);
                  dataQuantitySport2.push(item.quantity);
                });

                e.dataFamilyModel.forEach((item) => {
                  categoriesFamilyName.push(item.modelName);
                  dataQuantityFamily2.push(item.quantity);
                });
              } else if (index == 2) {
                e.dataMonth.forEach((item) => {
                  categoriesMonth.push(item.month);
                  dataQuantity3.push(item.quantity);
                });
                e.dataAutoModel.forEach((item) => {
                  categoriesmodelName.push(item.modelName);
                  dataQuantitymodel3.push(item.quantity);
                });

                e.dataSportModel.forEach((item) => {
                  categoriesSportName.push(item.modelName);
                  dataQuantitySport3.push(item.quantity);
                });

                e.dataFamilyModel.forEach((item) => {
                  categoriesFamilyName.push(item.modelName);
                  dataQuantityFamily3.push(item.quantity);
                });
              }
            });

            // const calMonth = categoriesMonth.length / 3;
            // const dataMonth = this.splitChunks(categoriesMonth, calMonth);
            // const dataMonth2 = this.splitChunks(dataQuantity, calMonth);
            // const calAuto = categoriesmodelName.length / 3;
            // const dataAuto = this.splitChunks(dataQuantitymodel, calAuto);
            this.chartOptions.xaxis.categories = categoriesMonth;
            this.chartOptions2.xaxis.categories = categoriesmodelName;
            this.chartOptions3.xaxis.categories = categoriesSportName;
            this.chartOptions4.xaxis.categories = categoriesFamilyName;

            this.series[0].data = dataQuantity;
            this.series[1].data = dataQuantity2;
            this.series[2].data = dataQuantity3;
            this.series2[0].data = dataQuantitymodel;
            this.series2[1].data = dataQuantitymodel2;
            this.series2[2].data = dataQuantitymodel3;

            this.series3[0].data = dataQuantitySport;
            this.series3[1].data = dataQuantitySport2;
            this.series3[2].data = dataQuantitySport3;

            this.series4[0].data = dataQuantityFamily;
            this.series4[1].data = dataQuantityFamily2;
            this.series4[2].data = dataQuantityFamily3;
            this.checkData();
            // this.checkSum();
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton true
          this.overlayFlag = false;
          this.load = true;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-md-center">
              <div class="col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12">
                    <div class="row">
                      <div class="col-sm-4 col-md-3">
                        <div class="mb-3 position-relative">
                          <label> สาขา:</label>

                          <multiselect
                            v-model="filter.branchIdSearch"
                            :options="localData"
                            label="nameTh"
                            :show-labels="false"
                            :multiple="true"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :limit-text="limitText"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            track-by="branchId"
                          >
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="fromMonth">ตั้งแต่:</label>

                          <multiselect
                            id="fromMonth"
                            v-model="filter.fromMonth"
                            :options="optionMonth"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            open-direction="bottom"
                            :class="{
                              'is-invalid':
                                submitform && $v.filter.fromMonth.$error,
                            }"
                          ></multiselect>
                          <div
                            v-if="submitform && $v.filter.fromMonth.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.filter.fromMonth.required">{{
                              errormessage
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4">
                        <div class="mb-3 position-relative">
                          <code>* </code> <label for="toMonth">ถึง:</label>
                          <multiselect
                            id="toMonth"
                            v-model="filter.toMonth"
                            :options="optionMonth"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            :class="{
                              'is-invalid':
                                submitform && $v.filter.toMonth.$error,
                            }"
                          ></multiselect>
                          <div
                            v-if="submitform && $v.filter.toMonth.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.filter.toMonth.required">{{
                              errormessage
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="year">ปี(ค.ศ):</label>
                          <multiselect
                            id="year"
                            v-model="filter.year"
                            :options="yearOption"
                            :show-labels="false"
                            :label="index"
                            :track-by="index"
                            :class="{
                              'is-invalid': submitform && $v.filter.year.$error,
                            }"
                          ></multiselect>

                          <div
                            v-if="submitform && $v.filter.year.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.filter.year.required">{{
                              errormessage
                            }}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-12 col-md-12 text-end mt-3 mb-3"
                      >
                        <btnComponent
                          @click="tooltipForm(filter.branchIdSearch)"
                          changeStyle="search"
                        >
                        </btnComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="row" v-show="isHidden">
              <div class="col-12">
                <p class="card-title">ภาพรวมงานขายสาขา เทียบเป้า(ปี)</p>
              </div>
              <!-- <div class="col-2"></div> -->
              <div class="row justify-content-md-center">
                <div class="col-md-8 col-sm-12 col-12">
                  <b-skeleton-wrapper :loading="loading">
                    <!-- skeleton  -->
                    <template #loading>
                      <b-skeleton-table></b-skeleton-table>
                    </template>
                    <!-- <div class="table-responsive mb-0">
                    <b-table
                      :items="branchSalesCompareWithSalesTarget"
                      :fields="fields"
                      :current-page="1"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      responsive="sm"
                      :bordered="bordered"
                    >
                      <template
                        #cell(target)="branchSalesCompareWithSalesTarget"
                      >
                        <p class="text-end">
                          {{ branchSalesCompareWithSalesTarget.item.target }}
                        </p>
                      </template>
                      <template
                        #cell(sales)="branchSalesCompareWithSalesTarget"
                      >
                        <p class="text-end">
                          {{ branchSalesCompareWithSalesTarget.item.sales }}
                        </p>
                      </template>
                      <template #cell(diff)="branchSalesCompareWithSalesTarget">
                        <p class="text-end">
                          {{ branchSalesCompareWithSalesTarget.item.diff }}
                        </p>
                      </template>
                      <template
                        #cell(percent)="branchSalesCompareWithSalesTarget"
                      >
                        <p class="text-end">
                          {{ branchSalesCompareWithSalesTarget.item.percent }}
                        </p>
                      </template>
                    </b-table>
                  </div> -->

                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr class="table-active">
                            <th>สาขา</th>
                            <th class="text-center">เป้า</th>
                            <th class="text-center">ขาย</th>
                            <th class="text-center">เทียบเป้า</th>
                            <th class="text-center">%(เทียบเป้า)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              item, index
                            ) in branchSalesCompareWithSalesTarget"
                            :key="index"
                          >
                            <td>{{ item.branch }}</td>
                            <td class="text-end">{{ item.target }}</td>
                            <td class="text-end">{{ item.sales }}</td>
                            <td class="text-end">{{ item.diff }}</td>
                            <td class="text-end table-danger">
                              {{ item.percent }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-skeleton-wrapper>
                </div>
              </div>
              <!-- <div class="col-2"></div> -->
            </div>

            <b-overlay
              v-if="!load"
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
            </b-overlay>
            <div class="row" v-else>
              <p class="card-title">ภาพรวมงานขาย (ปี)</p>
              <div class="col-md-2 col-sm-3">
                <b-card
                  :title="'CLY' + ' ' + filter.year"
                  tag="article"
                  style="max-width: 12rem"
                  class="mb-2 text-center"
                >
                </b-card>
                <!-- <div
                 
                > -->
                <b-card
                  v-for="(item, index) in yearSummary.data3Years"
                  :key="index"
                  :title="'CLY' + '' + item.year"
                  tag="article"
                  style="max-width: 12rem"
                  class="mb-2 text-center"
                >
                  <b-card-text>
                    {{ item.total }} คัน <br />
                    เฉลี่ยเดือนละ {{ item.avg }} คัน
                  </b-card-text>
                </b-card>
                <!-- </div> -->

                <b-card
                  :title="'เติบโต CLY' + ' ' + filter.year"
                  tag="article"
                  style="max-width: 12rem"
                  class="mb-2 text-center"
                >
                  <b-card-text>
                    {{ yearSummary.growthRate }}
                  </b-card-text>
                </b-card>
              </div>

              <div class="col-md-10 col-sm-9">
                <div class="row border border-white">
                  <apexchart
                    type="line"
                    height="300"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
                <div class="row">
                  <div class="col-md-4 border border-white">
                    <apexchart
                      type="bar"
                      height="300"
                      :options="chartOptions2"
                      :series="series2"
                    ></apexchart>
                  </div>
                  <div class="col-md-4">
                    <apexchart
                      type="bar"
                      height="300"
                      :options="chartOptions3"
                      :series="series3"
                    ></apexchart>
                  </div>
                  <div class="col-md-4">
                    <apexchart
                      type="bar"
                      height="300"
                      :options="chartOptions4"
                      :series="series4"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3" v-show="isHidden">
              <!-- <div class="col-12">
                <p class="card-title">ภาพรวมงานขายสาขา เทียบเป้า(ปี)</p>
              </div> -->
              <div class="row justify-content-md-center">
                <div class="col-md-8 col-sm-12 col-12">
                  <b-skeleton-wrapper :loading="loading">
                    <!-- skeleton  -->
                    <template #loading>
                      <b-skeleton-table></b-skeleton-table>
                    </template>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <!-- <thead class="table-active">
                      <tr>
                        <th scope="col">ชื่อ</th>
                        <th scope="col">ปี 2019</th>
                        <th scope="col">ปี 2020</th>
                        <th scope="col">ปี 2021</th>
                      </tr>
                    </thead> -->
                        <tbody>
                          <tr class="table-active">
                            <th scope="col">ชื่อ</th>
                            <th
                              v-for="(itemSum, index) in diffSummarySummary"
                              :key="'sum' + index"
                              class="text-center"
                            >
                              {{ index }}
                            </th>
                            <th scope="col" class="text-center">เทียบ</th>
                          </tr>
                          <tr>
                            <th colspan="5" class="table-primary">
                              Auto Models
                            </th>
                          </tr>
                          <tr
                            v-for="(itemA, index) in diffSummaryAutoModels"
                            :key="'auto' + index"
                          >
                            <td>{{ itemA.model }}</td>
                            <td
                              v-for="(ele, index) in itemA.detail"
                              :key="index"
                              class="text-end"
                            >
                              {{ ele.quantity }}
                            </td>

                            <td class="text-end">
                              <tr class="float-end">
                                {{
                                  itemA.detail[itemA.detail.length - 1].diff
                                }}
                              </tr>
                            </td>
                          </tr>
                          <tr>
                            <th colspan="5" class="table-primary">
                              Family Models
                            </th>
                          </tr>
                          <tr
                            v-for="(itemF, index) in diffSummaryFamilyModels"
                            :key="'family' + index"
                          >
                            <td>{{ itemF.model }}</td>
                            <td
                              v-for="(ele, index) in itemF.detail"
                              :key="index"
                              class="text-end"
                            >
                              {{ ele.quantity }}
                            </td>
                            <td class="text-end">
                              <tr class="float-end">
                                {{
                                  itemF.detail[itemF.detail.length - 1].diff
                                }}
                              </tr>
                            </td>
                          </tr>
                          <tr>
                            <th colspan="5" class="table-primary">
                              Sport Models
                            </th>
                          </tr>
                          <tr
                            v-for="(itemS, index) in diffSummarySportModels"
                            :key="'sport' + index"
                          >
                            <td>{{ itemS.model }}</td>
                            <td
                              v-for="(ele, index) in itemS.detail"
                              :key="index"
                              class="text-end"
                            >
                              {{ ele.quantity }}
                            </td>
                            <td class="text-end">
                              <tr class="float-end">
                                {{
                                  itemS.detail[itemS.detail.length - 1].diff
                                }}
                              </tr>
                            </td>
                          </tr>
                          <tr class="table-danger">
                            <th>รวม</th>
                            <td
                              class="text-end"
                              v-for="(itemSum, index) in diffSummarySummary"
                              :key="'sum' + index"
                            >
                              {{ itemSum }}
                            </td>
                            <td class="text-end">
                              {{ summary }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-skeleton-wrapper>
                </div>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
